<!--CopySucessButtonComponent-->
<template>
  <div class="relative flex items-center ml-auto">
    <button
      type="button"
      class="flex items-center justify-center min-w-[7rem] px-8 py-4 rounded-3xl hover:bg-interaction-hover active:bg-interaction-pressed"
      @click="handleCopy"
    >
      <span v-if="isTextButton" class="text-md leading-sm text-on-surface-elevation-3">{{
        buttonText
      }}</span>
      <s-icon
        v-else
        icon="ic-v2-community-copy-line"
        size="3xl"
        class="text-on-surface-elevation-2 flex"
      />
    </button>
    <p
      v-if="isSuccess"
      class="flex items-center gap-4 rounded-2xl px-12 py-[.6rem] absolute right-full mr-8 text-xs bg-neutral-variant-1 text-white"
    >
      <s-icon v-if="isTextButton" icon="ic-v2-control-check-bold-line" size="xl" />
      <s-icon v-else icon="ic-v2-control-check-line" size="xl" />
      <span class="text-nowrap">{{ successText ?? $t('studio.toast.link_copied') }}</span>
    </p>
  </div>
</template>
<script setup lang="ts">
import { defineProps, ref, toRefs } from 'vue';

import { handleCopyToClipboard } from '@/utils/string.util';
import { delayTime } from '@/utils/time.util';

const isSuccess = ref(false);

const props = defineProps<{
  copyText: string;
  buttonText: string;
  isTextButton: boolean;
  successText: string;
  debounce: number;
}>();

const { isTextButton, successText, debounce, copyText } = toRefs(props);

const handleCopy = async () => {
  handleCopyToClipboard(copyText.value);
  isSuccess.value = true;
  await delayTime(debounce.value);
  isSuccess.value = false;
};
</script>
