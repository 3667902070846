<template>
  <st-title :title="$t('studio.lnb.notice')" />
  <st-box>
    <a
      href=""
      class="inline-flex mb-8 text-md font-regular leading-sm text-on-surface-elevation-3 items-center gap-4"
      @click.prevent="navigateToList"
    >
      {{ $t('studio.lnb.notice') }}
      <s-icon icon="ic-v2-control-arrow-right-line" size="xl" class="flex text-placeholder" />
    </a>
    <div class="flex justify-between gap-4">
      <p
        class="flex flex-1 break-all gap-4 text-3xl font-bold leading-2xl text-on-surface-elevation-1"
      >
        <span class="break-all">{{ category }} {{ title }}</span>
        <s-new-badge v-if="isShowTagNew" variant="dot" class="shrink-0 !bg-orange500" />
      </p>
      <st-copy-button
        class="shrink-0"
        :copyText="fullPublicPath"
        :buttonText="$t('studio.group.notice.copy_text_button')"
        isTextButton
        :successText="$t('studio.group.notice.copied_toast_msg')"
        :debounce="3000"
      />
    </div>
    <span class="mt-4 text-sm leading-md text-on-surface-elevation-4 block shrink-0">
      {{ noticeDetail?.createDatetime ? getDateTimeByLocale(noticeDetail.createDatetime) : '' }}
    </span>
    <div
      v-for="(file, index) in attachmentFiles"
      :key="index"
      class="bg-neutral-variant-2 px-16 h-[3.8rem] rounded-lg flex items-center gap-[1rem] mt-8"
    >
      <p
        class="text-sm leading-md font-regular text-on-surface-elevation-3 break-all line-clamp-1 flex-1"
      >
        {{ file.filename ?? '-' }}
      </p>
      <button
        type="button"
        class="shrink-0 flex gap-4 items-center text-on-surface-elevation-2 text-xs leading-xs font-medium"
        @click="downLoadFile(file.linkCdn ?? '-', file.filename ?? '-')"
      >
        <s-icon icon="ic-v2-object-download-line" size="xl" class="flex" />
        {{ $t('studio.group.notice.file_download_btn') }}
      </button>
    </div>
    <safe-html
      class="my-20 flex-1 text-lg leading-lg text-on-surface-elevation-2 min-h-[49.4rem]"
      :html="noticeDetail?.content ?? ''"
      tag="div"
    />
    <s-button
      class="flex ml-auto mb-8"
      variant="outline"
      size="xs"
      :isDisabled="false"
      :hasArrow="false"
      @click="navigateToList"
    >
      {{ $t('studio.group.notice.notice_list_dir_btn') }}
      <!-- please check Dzung to update text for this key-->
    </s-button>
    <div
      v-if="prevNotice || nextNotice"
      class="bg-[var(--stds-glob-color-gray20)] py-8 px-16 rounded-xl shrink-0"
    >
      <div v-if="prevNotice" class="flex gap-16 items-center py-8">
        <s-icon
          icon="ic-v2-control-select-arrow-up-fill"
          size="xl"
          class="flex shrink-0 text-on-surface-elevation-2"
        />
        <a
          href=""
          class="text-sm leading-md text-on-surface-elevation-3 line-clamp-1 break-all"
          @click.prevent="navigateToNoticeDetail(prevNotice.articleId)"
        >{{ prevNotice.title }}</a>
        <span class="shrink-0 ml-auto text-sm leading-md text-on-surface-elevation-3">{{
          prevNotice?.createDatetime ? getDateTimeByLocale(prevNotice.createDatetime) : ''
        }}</span>
      </div>
      <div v-if="nextNotice" class="flex gap-16 items-center py-8">
        <s-icon
          icon="ic-v2-control-select-arrow-down-fill"
          size="xl"
          class="flex shrink-0 text-on-surface-elevation-2"
        />
        <a
          href=""
          class="text-sm leading-md text-on-surface-elevation-3 line-clamp-1 break-all"
          @click.prevent="navigateToNoticeDetail(nextNotice.articleId)"
        >{{ nextNotice.title }}</a>
        <span class="shrink-0 ml-auto text-sm leading-md text-on-surface-elevation-3">{{
          nextNotice?.createDatetime ? getDateTimeByLocale(nextNotice?.createDatetime) : ''
        }}</span>
      </div>
    </div>
  </st-box>
</template>
<script lang="ts" setup>
import { computed, ref, toRefs } from 'vue';
import { useRoute } from 'vue-router';

import { fetchMultipleFilesMetaDataApi } from '@/apis/file.api';
import { fetchNoticeDetailApi, fetchNoticeMoreApi } from '@/apis/notice.api';
import stCopyButton from '@/components/common/button/st-copy-button.vue';
import safeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import StTitle from '@/components/common/st-title.vue';
import { NOTICE_REQUEST_ID } from '@/constants/common.const';
import { NOTICE_PAGE_URL, PUBLIC_NOTICE_PAGE_URL } from '@/constants/url.const';
import { Confirmation, LocationCode } from '@/enums/common.enum';
import { useUserStore } from '@/stores/user.store';
import type { FileMeta, FileMetaData } from '@/types/common/file.type';
import type { NoticeDetailResponse, NoticeResponse } from '@/types/notice/notice-response.type';
import { getConfigs, redirectTo } from '@/utils/common.util';
import { getDateTimeByLocale } from '@/utils/date.util';
import { downLoadFile } from '@/utils/file.util';
import { isNewNotice } from '@/utils/notice.util';
import { useLocalePath } from '#imports';

const props = defineProps<{
  isPublic?: boolean;
}>();

const { isPublic } = toRefs(props);
const localePath = useLocalePath();
const { META_DATA_SERVICE_ID } = getConfigs();

const route = useRoute();
const currentArticleId = route.params.id as string;
const userStore = useUserStore();
const { userInfo } = userStore;

const fullPublicPath = `${window.location.host}${localePath(
  `${PUBLIC_NOTICE_PAGE_URL}/${currentArticleId}`
)}`;
const noticeDetail = ref<NoticeDetailResponse>();
const noticeMoreList = ref<NoticeResponse[]>([]);
const isShowTagNew = ref<boolean>(false);

const title = computed<string>(() => noticeDetail.value?.title ?? '');
const category = computed<string>(() =>
  noticeDetail.value?.headlineInfo.headlineName
    ? `[${noticeDetail.value.headlineInfo.headlineName}]`
    : ''
);

const currentNoticeIdx = computed<number>(() =>
  noticeMoreList.value.findIndex((item: NoticeResponse) => item.articleId === currentArticleId)
);
const nextNotice = computed<NoticeResponse>(
  () => noticeMoreList.value[currentNoticeIdx.value - 1] || null
);
const prevNotice = computed<NoticeResponse>(
  () => noticeMoreList.value[currentNoticeIdx.value + 1] || null
);

const getAttachmentInfo = async (seqList: string) => {
  const result = await fetchMultipleFilesMetaDataApi({
    serviceType: 'application_no',
    serviceId: META_DATA_SERVICE_ID.toString(),
    userType: 'member_no',
    userId: userInfo?.memberNo?.toString(),
    fileIdList: seqList.split(','),
    properties: ['link_cdn', 'filename']
  });
  if (!result) {
    return [];
  }

  return result.map((item: FileMeta) => ({
    linkCdn: item.data.linkCdn,
    filename: item.data.filename
  }));
};

const attachmentFiles = ref<FileMetaData[]>();

const init = async () => {
  const result = await fetchNoticeDetailApi({
    articleId: currentArticleId,
    requestId: NOTICE_REQUEST_ID.CM,
    requestMemberNo: userInfo?.memberNo ?? 0,
    headlineTitleYn: Confirmation.YES
  });
  if (!result) {
    return;
  }
  noticeDetail.value = result;
  if (isNewNotice(noticeDetail.value?.createDatetime ?? 0)) {
    isShowTagNew.value = true;
  }
  if (noticeDetail.value.attachInfo?.fileInfo?.seqList) {
    attachmentFiles.value = await getAttachmentInfo(noticeDetail.value.attachInfo.fileInfo.seqList);
  }

  noticeMoreList.value = await fetchNoticeMoreApi(currentArticleId, {
    size: 1,
    translationYn: Confirmation.YES,
    locationCode: LocationCode.ALL
  });
};

const navigateToNoticeDetail = (id: string) => {
  const path = isPublic.value ? `${PUBLIC_NOTICE_PAGE_URL}/${id}` : `${NOTICE_PAGE_URL}/${id}`;
  redirectTo(path);
};
const navigateToList = () => {
  const path = isPublic.value ? PUBLIC_NOTICE_PAGE_URL : NOTICE_PAGE_URL;
  redirectTo(path);
};

init();
</script>
